import {
  LOADING,
  REGISTER_TOKEN,
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  CLIENT_CHECKIN_SUCCESS,
  CLIENT_CHECKIN_ERROR,
  CLEAR_ERROR_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
} from "../types"

const INITIAL_STATE = {
  loading: false,
  authenticated: false,
  authError: "",
  user: null,
  registerToken: "",
  registerEmail: "",
  clientCheckinSuccess: "",
  clientCheckinError: "",
}

const AUTH_REDUCER = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case REGISTER_TOKEN:
      return {
        ...state,
        registerToken: action.payload.token,
        registerEmail: action.payload.email,
      }
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        authError: "",
        user: action.payload,
      }
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        loading: false,
        authError: action.payload,
        user: null,
      }
    case UNAUTH_USER:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERROR_SUCCESS:
      return {
        ...state,
        registerToken: "",
        registerEmail: "",
        clientCheckinSuccess: "",
        clientCheckinError: "",
        authError: "",
        forgotPasswordError: "",
        forgotPasswordSuccess: "",
      }
    case CLIENT_CHECKIN_SUCCESS:
      return {
        ...state,
        loading: false,
        clientCheckinSuccess: action.payload,
        clientCheckinError: null,
      }
    case CLIENT_CHECKIN_ERROR:
      return {
        ...state,
        loading: false,
        clientCheckinSuccess: null,
        clientCheckinError: action.payload,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordError: "",
        forgotPasswordSuccess: action.payload,
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotPasswordError: action.payload,
        forgotPasswordSuccess: "",
      }
    default:
      return state
  }
}

export default AUTH_REDUCER
