// AUTH
export const LOADING = "loading"

export const REGISTER_TOKEN = "register_token"

export const FORGOT_PASSWORD_ERROR = "forgot_password_error"
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success"

export const AUTH_USER = "auth_user"
export const UNAUTH_USER = "unauth_user"
export const AUTH_ERROR = "auth_error"

export const CLEAR_ERROR_SUCCESS = "clear_error_success"
export const CLIENT_CHECKIN_SUCCESS = "client_checkin_success"
export const CLIENT_CHECKIN_ERROR = "client_checkin_error"
