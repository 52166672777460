export { default as wrapRootElement } from "./src/redux"

export const onServiceWorkerUpdateReady = () => {
  // Checking for new update - asking for refresh
  const answer = window.confirm(
    `Y3T Coaching has been updated.\nReload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
