// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-app-admin-index-js": () => import("./../../../src/pages/app/admin/index.js" /* webpackChunkName: "component---src-pages-app-admin-index-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-login-index-js": () => import("./../../../src/pages/app/login/index.js" /* webpackChunkName: "component---src-pages-app-login-index-js" */),
  "component---src-pages-app-profile-create-checkin-index-js": () => import("./../../../src/pages/app/profile/create-checkin/index.js" /* webpackChunkName: "component---src-pages-app-profile-create-checkin-index-js" */),
  "component---src-pages-app-profile-index-js": () => import("./../../../src/pages/app/profile/index.js" /* webpackChunkName: "component---src-pages-app-profile-index-js" */),
  "component---src-pages-app-profile-payment-settings-index-js": () => import("./../../../src/pages/app/profile/payment-settings/index.js" /* webpackChunkName: "component---src-pages-app-profile-payment-settings-index-js" */),
  "component---src-pages-app-profile-previous-checkins-index-js": () => import("./../../../src/pages/app/profile/previous-checkins/index.js" /* webpackChunkName: "component---src-pages-app-profile-previous-checkins-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-forgot-password-index-js": () => import("./../../../src/pages/forgot-password/index.js" /* webpackChunkName: "component---src-pages-forgot-password-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-temp-js": () => import("./../../../src/pages/indexTemp.js" /* webpackChunkName: "component---src-pages-index-temp-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-success-index-js": () => import("./../../../src/pages/payment/success/index.js" /* webpackChunkName: "component---src-pages-payment-success-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-success-index-js": () => import("./../../../src/pages/register/success/index.js" /* webpackChunkName: "component---src-pages-register-success-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

